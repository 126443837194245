import React from 'react'
import { Button } from '@mui/material'

function InfinitePager({ setPage, pages }) {
  return (
    <div className={'pagger infinite'}>
      <Button color={'secondary'} variant={'contained'} disableElevation onClick={() => setPage(pages.next)}>
        En voir plus
      </Button>
    </div>
  )
}

export default InfinitePager
