import React from 'react'
import { Pagination } from '@mui/material';
import { useMediaQuery } from '@mui/material'

function SteppedPager({ setPage, pages, page }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <div className={'pagger stepped'}>
      <Pagination
        count={pages.last}
        page={page}
        size={isMobile ? 'medium' : 'large'}
        onChange={(e, page) => setPage(page)}
        siblingCount={isMobile ? 0 : 1}
      />
    </div>
  )
}

export default SteppedPager
