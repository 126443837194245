import React from 'react'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'

const Filters = ({ pages, nbPerPage, changeNbPerPage, stepper, sorter, sortOrder, setSortOrder }) => {

  return (
    <Grid container className="filters" direction="row" justifyContent="flex-end" alignItems="flex-end" spacing={3}>
      {pages.total > 0 && stepper.length > 1 && (
        <Grid item className="nb_elem">
          <label className={'fixlh'}>Number per page</label>
          <FormControl>
            <Select
              value={nbPerPage}
              variant={'standard'}
              displayEmpty
              onChange={(e) => changeNbPerPage(e.target.value)}
            >
              {stepper.map((step, index) => {
                return (
                  <MenuItem value={step} key={index}>
                    {step}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {sortOrder !== false && sorter && (
        <Grid item className="sort_order">
          <FormControl>
            <InputLabel>Sort by</InputLabel>
            <Select value={sortOrder} variant={'standard'} onChange={(e) => setSortOrder(e.target.value)}>
              {sorter.map(({ label, value }, index) => {
                return (
                  <MenuItem value={value} key={index}>
                    {label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
    </Grid>
  )
}

export default Filters
