import React, { useEffect, useState } from 'react'
import ItemsListItem from 'components/item/ItemsListItem'
import { API_getItems } from 'js/api/Items'
import { LinearProgress } from '@mui/material'
import { getPages } from 'js/api'
import update from 'immutability-helper'
import { Alert } from '@mui/material';
import qs from 'qs'
import _ from 'lodash'
import { SORT_ORDER } from 'js/services/Item'
import Filters from 'components/commons/listItems/Filters'
import InfinitePager from 'components/commons/listItems/InfinitePager'
import SteppedPager from 'components/commons/listItems/SteppedPager'
import clsx from 'clsx'
import styles from './ItemsListItem.module.scss'
import { API_getGroupItems } from "js/api/Groups";

function ItemsList({
  pagination = 'stepped',
  searchParams = null,
  nbLines = 10,
  groupId = null,
  sortBy = '', //order[updatedAt]desc
  showFilters = true
}) {
  const [items, setItems] = useState([])
  const [busy, setBusy] = useState(true)
  const [rdy, setRdy] = useState(false)
  const [pages, setPages] = useState(getPages())
  const [page, setPage] = useState(1)
  const [nbPerPage, setNbPerPage] = useState(nbLines)
  const [sortOrder, setSortOrder] = useState(sortBy)
  const stepped = pagination === 'stepped'
  const infinite = pagination === 'infinite'

  console.log('itemList')

  const stepper = [10, 20, 40, 100].filter((value) => value <= pages.total)
  const sorter = SORT_ORDER

  const extraFilters = searchParams && _.pickBy(qs.parse(searchParams))

  useEffect(() => {
    setBusy(true)
    loadResults().then(() => {
      setBusy(false)
    })
  }, [page, sortOrder])

  //Initial loading
  useEffect(() => {
    !busy && !rdy && setRdy(true)
  }, [busy])

  useEffect(() => {
    if (!busy) {
      if (page !== 1) {
        setPage(1)
      } else {
        loadResults()
      }
    }
  }, [nbPerPage])

  const loadResults = async () => {
    try {
      const filters = {
        page: page ?? 1,
        limit: nbPerPage,
        ...qs.parse(sortOrder),
        ...extraFilters
      }

      let response;
      if(groupId)
      {
        response = await API_getGroupItems(groupId, filters, { full: true })
      }
      else {
        response = await API_getItems(filters, { full: true })
      }

      if (infinite) {
        setItems(update(items, { $push: response['hydra:member'] }))
      }

      if (stepped) {
        setItems(update(items, { $set: response['hydra:member'] }))
      }

      const pageBuilder = getPages(response)
      setPages(pageBuilder)
    } catch (error) {
    }
  }

  const changeNbPerPage = async (value) => {
    const limit = value > pages.total ? pages.total : value
    setNbPerPage(limit)
  }

  if (!rdy) {
    return <LinearProgress color="secondary" />
  }

  return (
    <div className={styles.itemsList}>
      <div className={clsx('content', 'xhr', { busy })}>
        {!_.isEmpty(extraFilters) && <div className={'search_query'}>Your research : "{extraFilters.name}"</div>}
        {showFilters && pages.total > 0 && (
          <Filters
            {...{
              pages,
              nbPerPage,
              changeNbPerPage,
              stepper,
              sorter,
              sortOrder,
              setSortOrder
            }}
          />
        )}
        {pages.total === 0 && <Alert severity={'info'} sx={{mt: 3}}>{groupId ? `No item are shared on this group yet , please come back later` : `No results were found for your search`}</Alert>}
        {items.map((item) => {
          return <ItemsListItem key={item['@id']} item={item} />
        })}
        {infinite && pages.next && (
          <InfinitePager
            {...{
              setPage,
              pages
            }}
          />
        )}
        {stepped && pages.total > nbPerPage && (
          <SteppedPager
            {...{
              setPage,
              pages,
              page
            }}
          />
        )}
      </div>
    </div>
  )
}

export default ItemsList
