import { apiCall } from 'js/api'

export const API_getItems = (filters, options) =>
  apiCall({
    uri: 'sharedItems',
    params: filters,
    ...options
  })
    .then((items) => Promise.resolve(items))
    .catch((error) => Promise.reject(error))

export const API_getItem = (id) =>
  apiCall({
    uri: `items/${id}`,
    method: 'GET',
    collection: false
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))

export const API_getItemWords = (id, filters, options) =>
  apiCall({
    uri: `items/${id}/words`,
    params: filters,
    ...options
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))

export const API_getItemWordsStatus = (id, params, options) =>
  apiCall({
    uri: `items/${id}/wordsStatus`,
    method: 'GET',
    params,
    collection: true,
    ...options
  })
    .then((item) => Promise.resolve(item))
    .catch((error) => Promise.reject(error))

export const API_getItemWordsStatusPdf = (id, params, options) => {
  return API_getItemWordsStatus(
    id,
    { ...params, ...{ toPdf: true } },
    {
      ...options,
      ...{
        responseType: 'blob',
        raw: true
      }
    }
  )
}
