import NumberFormat from 'react-number-format'
import React from 'react'

export default function NumberFormatCustom(props) {
  const { inputRef, onChange, numberFormatProps = {}, ...other } = props
  const { limit, decimalScale, fixedDecimalScale, thousandSeparator, displayType } = numberFormatProps

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => displayType !== 'text' && onChange(values.floatValue || 0)}
      thousandSeparator={thousandSeparator !== undefined ? thousandSeparator : ' '}
      decimalSeparator=","
      autoComplete="none"
      decimalScale={decimalScale ?? 2}
      fixedDecimalScale={fixedDecimalScale ?? true}
      isAllowed={(values) => {
        const { floatValue } = values
        if (!limit) return true
        if (limit.max !== undefined) {
          if (floatValue > limit.max) return false
        }
        if (limit.min !== undefined) {
          if (floatValue < limit.min) return false
        }
        return true
      }}
    />
  )
}
