import { apiCall } from 'js/api'

export const API_getGroups = (params) =>
  apiCall({
    uri: 'groups',
    params
  })
    .then((groups) => Promise.resolve(groups))
    .catch((error) => Promise.reject(error))

export const API_getGroup = (id) =>
  apiCall({
    uri: `groups/${id}`,
    method: 'GET',
    collection: false
  })
    .then((group) => Promise.resolve(group))
    .catch((error) => Promise.reject(error))

export const API_getGroupItems = (groupId, filters, options) => {
  return apiCall({
    uri: `groups/${groupId}/items`,
    params: filters,
    ...options
  })
    .then((items) => Promise.resolve(items))
    .catch((error) => Promise.reject(error))
}
export const API_joinGroup = (groupId) =>
  apiCall({
    uri: `groups/${groupId}/joinGroup`,
    method: 'GET'
  })
    .then((groups) => Promise.resolve(groups))
    .catch((error) => Promise.reject(error))

export const API_leaveGroup = (groupId) =>
  apiCall({
    uri: `groups/${groupId}/leaveGroup`,
    method: 'GET'
  })
    .then((groups) => Promise.resolve(groups))
    .catch((error) => Promise.reject(error))
