import React from 'react'
import { Button, Grid } from '@mui/material'
import NumberFormatCustom from 'components/commons/NumberFormatCustom'
import ButtonLink from 'components/commons/ButtonLink'
import clsx from 'clsx'
import { useWidth } from "js/hook";


function ItemsListItem({ item }) {

  const width = useWidth();

  return (
    <div className={clsx(['item', width])}>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
        <Grid item className={'left'} xs={12} sm={6} md={'auto'}>
          <div className={'name'}>{item.name}</div>
          {item.author && (
            <div className="author">
              <span>{item.author}</span>
            </div>
          )}
        </Grid>
        <Grid item className={'right'} xs={12} sm={6} md={'auto'}>
          <div className="words">
            <span className={'title'}>Number of words : </span>
            <NumberFormatCustom className={'value'} value={item.wordsCount} displayType={'text'} numberFormatProps={{ decimalScale: 0 }} />
          </div>
          <div className="actions">
            <Button
              size={'small'}
              className={'snow'}
              disableElevation
              variant={'outlined'}
              href={item['@id']}
              component={ButtonLink}
            >
              Study
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default ItemsListItem
