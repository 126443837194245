export const SORT_ORDER = [
  {
    label: 'The oldest',
    value: 'order[updatedAt]=asc'
  },
  {
    label: 'The most recent',
    value: 'order[updatedAt]=desc'
  },
  {
    label: 'The fewest words',
    value: 'order[wordsCount]=asc'
  },
  {
    label: 'The most word',
    value: 'order[wordsCount]=desc'
  },
  {
    label: 'Name [A-Z]',
    value: 'order[name]=asc'
  },
  {
    label: 'Name [Z-A]',
    value: 'order[name]=desc'
  },
  {
    label: 'Author [A-Z]',
    value: 'order[author]=asc'
  },
  {
    label: 'Author [Z-A]',
    value: 'order[author]=desc'
  }
]
